@import "src/theme/variables.scss";

.no-arrow-number {

    /* Remove arrow increase or decrease number */
    /* Chrome, Safari, Edge, Opera */
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    [type=number] {
        -moz-appearance: textfield;
    }
}
