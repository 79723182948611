@import "src/theme/variables.scss";

.cycle-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.cycle {
    font-size: 23px;
    color: var(--ion-color-text-primary);

    &__active {
        color: var(--ion-color-primary-greeny-blue);
    }
}

.amount-width {
    max-width: 80px;
}

.date-amount-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 5px;

    &__date {
        margin-right: 10px !important;
        color: white !important;
    }

    &__amount {
        width: 110px !important;

        /* Remove arrow increase or decrease number */
        /* Chrome, Safari, Edge, Opera */
        ::-webkit-outer-spin-button,
        ::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        [type=number] {
            -moz-appearance: textfield;
        }
    }

    &__hidden {
        visibility: hidden;
        pointer-events: none;
    }
}

.add-due-date-button {
    margin-top: 30px;
    font-size: 14px;
}
