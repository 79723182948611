@import "src/theme/variables.scss";

.custom-ion-alert {
    --backdrop-opacity: 0;
    --background: white;

    .alert-wrapper {
        border-radius: 0;
        box-shadow: 0 3px 6px #00000029;
    }

    .alert-message {
        font-weight: 500;
        text-align: left;
    }

    .alert-checkbox-group {
        border-top: none;
        padding-bottom: 20px;

        .alert-button-inner {
            flex-direction: row-reverse;
            padding-left: 10px;
            padding-right: 20px;
        }

        .alert-checkbox-icon {
            border-color: #2E3C42;
            border-radius: 5px;
            border-width: 2px;
            width: 20px;
            height: 20px;
        }

        .alert-checkbox-label {
            white-space: normal;
        }

        [aria-checked=true].alert-checkbox-button {
            .alert-checkbox-icon {
                background-color: var(--ion-color-primary-greeny-blue);
                border-color: #2E3C42;

                .alert-checkbox-inner {
                    left: 6px;
                    top: 1px;
                }
            }
        }
    }

    .alert-button-group {
        gap: 10px;
        padding-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;
        .alert-button {
            // Expect to be 'cancel' button
            &:first-child {
                border: none;
                border-radius: var(--ion-border-radius-primary);

                font-weight: 600;
                color: var(--ion-color-text-primary);
            }
            
            // Expect to be 'submit' button
            &:last-child {
                font-weight: 600;

                border: none;
                border-radius: var(--ion-border-radius-primary);

                color: white;
                background-color: var(--ion-color-primary-greeny-blue);
            }

            &:not(:first-child):not(:last-child) {
                border: none;
                border-radius: var(--ion-border-radius-primary);

                color: var(--ion-color-text-primary);
            }
        }
    }
}
