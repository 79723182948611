/*
font-weight: 700; #Bold
font-weight: 600; #SemiBold
font-weight: 500; #Medium
*/
// ========= Lexend Deca =========
@font-face {
    font-family: "Lexend Deca";
    font-style: normal;
    font-weight: 700;
    display: swap;
    src: url("../../public/assets/fonts/Lexend_Deca/LexendDeca-Bold.woff2") format("woff2");
}

@font-face {
    font-family: "Lexend Deca";
    font-style: normal;
    font-weight: 500;
    display: swap;
    src: url("../../public/assets/fonts/Lexend_Deca/LexendDeca-Medium.woff2") format("woff2");
}

@font-face {
    font-family: "Lexend Deca";
    font-style: normal;
    font-weight: normal;
    src: url("../../public/assets/fonts/Lexend_Deca/LexendDeca-Regular.woff2") format("woff2");
}

@font-face {
    font-family: "Lexend Deca";
    font-style: normal;
    font-weight: 600;
    display: swap;
    src: url("../../public/assets/fonts/Lexend_Deca/LexendDeca-SemiBold.woff2") format("woff2");
}
// ========= Lexend Deca =========

// ========= Poppins =========
@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    display: swap;
    src: url("../../public/assets/fonts/Poppins/Poppins-Bold.woff2") format("woff2");
}

@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    display: swap;
    src: url("../../public/assets/fonts/Poppins/Poppins-Medium.woff2") format("woff2");
}

@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    src: url("../../public/assets/fonts/Poppins/Poppins-Regular.woff2") format("woff2");
}

@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    display: swap;
    src: url("../../public/assets/fonts/Poppins/Poppins-SemiBold.woff2") format("woff2");
}
// ========= Poppins =========

// ========= Nunito Sans =========
@font-face {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 700;
    display: swap;
    src: url("../../public/assets/fonts/Nunito_Sans/NunitoSans-Bold.woff2") format("woff2");
}

@font-face {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: normal;
    src: url("../../public/assets/fonts/Nunito_Sans/NunitoSans-Regular.woff2") format("woff2");
}

@font-face {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    display: swap;
    src: url("../../public/assets/fonts/Nunito_Sans/NunitoSans-SemiBold.woff2") format("woff2");
}
// ========= Nunito Sans =========

/*
 DO NOT IMPLEMENT, one of these causes bugs.
 Implement it separately for your page if you want you.

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}
*/

.first-form-control {
    margin: 0 42px;

    &--first-input-margin > *:nth-of-type(n) {
        margin-top: 34px;
    }

    &--second-input-margin > *:nth-of-type(n) {
        margin-top: 17px;
    }
}

.second-form-control {
    margin: 0 63px;

    &--first-input-margin > *:nth-of-type(n) {
        margin-top: 15px;
    }
}

.new-tx-second-form-control {
    margin: 0 48px;

    &--first-input-margin > *:nth-of-type(n) {
        margin-top: 15px;
    }
}

.third-form-control {
    margin: 0 21px;

    &--first-input-margin > *:nth-of-type(n) {
        margin-top: 17px;
    }
}

.new-transaction-form-control {
    margin: 0 33px;

    &--first-input-margin > *:nth-of-type(n) {
        margin-top: 15px;
    }
}

.text-margin {
    margin: 0 21px;
}

.ion-sm-margin-top {
    margin-top: 6px;
}

ion-label {
    white-space: normal !important;
}

input.PhoneInputInput{
    height: 40px;
    border-radius: 7px;
    padding-left: 10px;
    background-color: white!important;
}

// container code confirmation phone
.vi__character {
    border-radius: 7px;
}
