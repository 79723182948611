@import "src/theme/variables.scss";

ion-tab-bar {
    background-color: unset;
    height: 88px;
    border: unset;
}

@media (min-width: 992px) {
    ion-tab-bar {
        visibility: hidden;
    }
}

@media (max-width: 768px) {
    ion-tab-button {
        &.placeholder-tab-btn {
            display: none;
        }
    }
}

ion-tab-button {
    --ripple-color: var(--ion-color-text-primary);
    --color: var(--ion-color-text-primary);
    background: var(--ion-color-text-secondary);
    --border: none;
    height: 67px;
    margin-top: 28px;

    &.tab-selected {
        .icon-container {
            border: 1px solid #dedede;
            border-radius: 33px;
            background-color: #dedede;
            box-shadow: inset 0px 0px 6px #00000061;

            svg {
                color: var(--ion-color-primary-greeny-blue);
            }
        }
    }

    ion-label {
        color: var(--ion-color-text-primary);
        font-size: 10px;
        font-weight: 600;
    }

    .icon-container {
        padding: 4px 27px 0px 27px;
        margin-bottom: 0px;

        svg {
            color: var(--ion-color-text-primary);
        }
    }
}

#euro-sign-button-tab {
    position: absolute;
    overflow: visible !important;
    height: 120px !important;
    border-radius: 33px;
    background: unset;

    .euro-sign-container {
        transform: translate3d(0, 0, 0);
        color: white;

        background: var(--ion-color-primary-greeny-blue);
        border-radius: var(--ion-ntb-border-radius);

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        margin-top: -36px;

        width: 124px;
        height: 65px;

        box-shadow: var(--ion-ntb-box-shadow);

        .new-text {
            font-size: 10px;
            font-weight: var(--ion-font-weight-semi-bold);
        }
    }
}
