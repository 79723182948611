  ion-modal.modal-inside-payment {
    --height: 400px;
    --max-height: 80%; 
    --border-radius: 16px; 
    --box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2); 
    --max-width: 95%;
  }
  
  .modal-content {
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  ion-modal.modal-inside-payment ion-backdrop {
    --backdrop-opacity: 0.4; 
  }