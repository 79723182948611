@import "src/theme/variables.scss";

.swiper-slide {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    flex-basis: content;

    &:first-child {
        margin-left: var(--ion-margin-home-page);
    }
}

.swiper-pagination-bullet {
    visibility: hidden;
}
